import Player from '@vimeo/player';

$(document).ready(function($) {

	let thumbnails = $('.partial-video__thumbnail');

	$(thumbnails).click(startVideo);

	function startVideo() {
		let id = $(this).attr('data-id');
		let video = document.getElementById(id);

		if(video) {
			if(video.classList.contains('vimeo-iframe')) {
				let player = new Player(video);
				player.play();
			} else if(video.classList.contains('youtube-iframe')) {
				let iframe = $('#' + id);
				let videoUrl = $(iframe).prop('src');
				videoUrl += '?autoplay=1';
				$(iframe).prop('src', videoUrl);
			} else {
				video.play();
			}
		}

		$(this).addClass('partial-video__thumbnail--inactive');
	}

});
